import { Component, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponentBase } from "../../../../shared/common/app-component-base";
import { CreateOrEditProductOptionDto, FundraisersServiceProxy, OrderProductOrPackageDto, ProductOrPackageDto, SharedOrdersServiceProxy, StudentContactsServiceProxy } from "../../../../shared/service-proxies/service-proxies";
import { AppConsts } from "@shared/AppConsts";
import { error } from "console";


@Component({
    // do we need a template?
    templateUrl: './fundraiser-home.component.html'
})
export class FundraiserHomeComponent extends AppComponentBase {

    constructor(
        injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _fundraisersServiceProxy: FundraisersServiceProxy,
        private _sharedOrdersServiceProxy: SharedOrdersServiceProxy,
        private _studentContactsServiceProxy: StudentContactsServiceProxy
    ) {
        super(injector);
    }

    fundraiserUserId: number;
    slugOrFundraiserId: string;
    ngOnInit(): void {
        this._activatedRoute.params.subscribe(params => {
            let orderId: number;
            orderId = params.orderId;

            if (orderId) {
                this._fundraisersServiceProxy.getIsFundraiserLaunchathon(0, '', orderId).subscribe(result => {
                    if (result.isLaunchathon) {
                        this._router.navigate(['app', 'main', 'launchathonFundraiserHome', 'edit', orderId]);
                    }
                    else {
                        this._router.navigate(['app', 'main', 'productFundraiserHome', 'edit', orderId]);
                    }
                });
            }

            else {
                this.slugOrFundraiserId = params.slug ?? params.fundraiserId; // params.fundraiserId is for old OrderInfo/:fundraiserId/:fundraiserUserId route.


                if (params.id) {
                    // this is an old anonymous non-launchathon link of the form:
                    // /app/main/fundraiser/<fundraiserId>/placeOrder
                    this.fundraiserUserId = 0;
                    this.slugOrFundraiserId = params.id

                }
                else if (params.fundraiserUserId == 'student') {
                    // this is an old student link.
                    this.fundraiserUserId = params.slug;
                }
                else if (params.fundraiserUserId == 'fundraiser') {
                    // this is an old anonymous link.
                    this.fundraiserUserId = 0;
                }
                else {
                    // this is a new link (produced by post product-fundraiser feature deploy code)
                    this.fundraiserUserId = params.fundraiserUserId;
                }
                                
                if(this.slugOrFundraiserId.charAt(0) === AppConsts.FUNDRAISER_STUDENT_CONTACT_SLUG_PREFIX) {                   

                    const queryParams = {
                        fscSlug: this.slugOrFundraiserId
                    };    

                    if (params.automatedMessageType) {
                        queryParams['automatedMessageType'] = params.automatedMessageType;
                    }
                    
                    this._studentContactsServiceProxy.getFundraiserUserIdFromSlug(this.slugOrFundraiserId).subscribe(result => {
                        this.fundraiserUserId = result;

                        this._fundraisersServiceProxy.getIsFundraiserLaunchathon(this.fundraiserUserId, this.slugOrFundraiserId, undefined).subscribe(result => {
                            let studentOrFundraiser = this.fundraiserUserId > 0 ? 'student' : 'fundraiser'
                            let id = this.fundraiserUserId > 0 ? this.fundraiserUserId : result.slug;
                            if (result.isLaunchathon) {
                                this._router.navigate(['launchathonFundraiserHome', studentOrFundraiser, id], { queryParams: queryParams });
                            }
                            else {
                                this._router.navigate(['productFundraiserHome', studentOrFundraiser, id], { queryParams: queryParams });
                            }
                        }); 
                    });                  
                }
                else {
                    this._fundraisersServiceProxy.getIsFundraiserLaunchathon(this.fundraiserUserId, this.slugOrFundraiserId, undefined).subscribe(result => {
                        let studentOrFundraiser = this.fundraiserUserId > 0 ? 'student' : 'fundraiser'
                        let id = this.fundraiserUserId > 0 ? this.fundraiserUserId : result.slug;
                        let viewSample = window.location.pathname.includes('viewSample');
                        if (result.isLaunchathon) {
                            if (viewSample) {
                                this._router.navigate(['launchathonFundraiserHome', studentOrFundraiser, 'viewSample', id]);
                            }
                            else {
                                this._router.navigate(['launchathonFundraiserHome', studentOrFundraiser, id]);
                            }
                        }
                        else {
                            if (viewSample) {
                                this._router.navigate(['productFundraiserHome', studentOrFundraiser, 'viewSample', id]);
                            }
                            else {
                                this._router.navigate(['productFundraiserHome', studentOrFundraiser, id]);
                            }
                        }
                    },
                    error => {
                        console.error('getIsFundraiserLaunchathon an error occurred:', error);
                        this._router.navigate(['notfound']);
                    }); 
                }                                               
            }
        });
    }
}

export interface GoalNumbers {
    rightTitle: string;
    rightAmount: number;
    leftTitle: string;
    leftAmount: number;
    progressBarValue: string;
    name: string;
}

export class ProductOrPackageViewModel {
    public orderProducts: OrderProductOrPackageDto[];
    public productDetails: ProductOrPackageDto;
    public productOptions: CreateOrEditProductOptionDto[];
}