import { CurrencyPipe, DOCUMENT, Location } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Inject, Injector, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { appModuleAnimation } from "../../../../shared/animations/routerTransition";
import { AppComponentBase } from "../../../../shared/common/app-component-base";
import {
    AddressDto, CreateISponsorAccountDto, CreateOrEditOrderDetailDto, CreateOrEditProductOptionDto, CreditCardDto,
    FundraisersServiceProxy,
    FundraiserUserSelector, ListItemLookupTableDto, ListItemsServiceProxy, OrderDetailsServiceProxy, OrderProductOrPackageDto, OrderTotalsDto,
    OrderTotalsInput, RepInfoDto, RepServiceProxy, SharedOrdersServiceProxy
} from "../../../../shared/service-proxies/service-proxies";
import { ProductOrPackageViewModel } from "../fundraiser-home/fundraiser-home.component";
import {  } from '@angular/common';

@Component({
    selector: 'checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.less'],
    animations: [appModuleAnimation()]
})
export class CheckoutComponent extends AppComponentBase implements OnInit {
    @ViewChild('orderForm', { read: NgForm, static: true }) orderForm: any;
    @ViewChild('teamMemberSelect', { read: NgModel }) teamMemberSelect: NgModel;

    @Input() donationAmountInput: number;
    @Input() donateByRoundingOrderTo: number;
    @Input() fundraiserIdInput: number;
    @Input() fundraiserUserIdInput: number;
    @Input() fundraiserName: string;
    @Input() logoUrl: string;
    @Input() organizationName: string;
    @Input() availableSellers: FundraiserUserSelector[];
    @Input() teamMemberIdInput: number;
    @Input() teamMemberNameInput: string;
    @Input() isLaunchathon = false;
    @Input() productAndPackageViewModels: ProductOrPackageViewModel[];
    @Input() tenantLogoUrl: string;

    @Input() paymentMethods: ListItemLookupTableDto[];
    @Input() checksPayableName;
    @Input() creditCardText;
    @Input() convenienceFee;
    @Input() directShipAvailable: boolean;
    @Input() forceDirectShip: boolean;
    @Input() acceptDonations;
    @Input() guestPayCCOption: number;
    @Input() studentCreditCardOnly;
    @Input() repInfo: RepInfoDto;
    @Input() guestPayMode: number;

    @Input() isDonationOnly: boolean;

    @Input() fundraiserStudentContactSlug: string;
    @Input() automatedMessageType: string;

    selectedProductOrders: ProductOption[];
    setSelectedProductOrders(): ProductOption[] {
        if (!this.productAndPackageViewModels || this.productAndPackageViewModels.length <= 0) return [];
        let result: ProductOption[] = [];
        this.productAndPackageViewModels?.forEach(pvm => {
            pvm.orderProducts.forEach(op => {
                if (op.quantity >= 1) {
                    let productOption: ProductOption = {
                        productName: pvm.productDetails.name,
                        productOptionName: this.productOptions[op.productOptionId]?.name,
                        price: pvm.productDetails.price,
                        quantity: op.quantity,
                        productId: pvm.productDetails.id,
                        productOptionId: op.productOptionId,
                    };
                    result.push(productOption);
                }
            });
        });
        this.selectedProductOrders = result;
    }

    @Input() productOptions: { [id: number]: CreateOrEditProductOptionDto };
    @Input() isGuest: boolean;
    @Input() shareLink: string;

    @Input() defaultOrderDetailToDirectShip: boolean;

    @Input() orderDetailId: number;
    @Input() orderDetail: CreateOrEditOrderDetailDto;
    @Input() isInitialLoadForEdit: boolean;
    @Input() tipInfoTitle: string;
    @Input() tipInfo: string;

    // ----- iSponsor -----
    @Input() isISponsorEnabled: boolean = false;

    @Output() orderComplete: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelOrder: EventEmitter<any> = new EventEmitter<any>();
    @Output() goBack: EventEmitter<OrderDetailPlusProductAndPackageViewModels> = new EventEmitter<OrderDetailPlusProductAndPackageViewModels>();

    orderTotalsUpdateResult: OrderTotalsDto;
    firstName: string;
    lastName: string;
    expires: string;

    states: ListItemLookupTableDto[] = [];

    _donation: number;
    get donation(): number {
        return this._donation;
    }
    set donation(value: number) {
        this._donation = value;
        this.orderDetail.customDonation = this.donationValue;

        // if user directly edits the donation total,
        // we no - longer want donation value to track the item total price value
        // as it would if they selected a round-up donation value.
        this.donateByRoundingOrderTo = 0;
    }

    tipOptions: string[] = ['Other', '30%', '25%', '20%', '15%', '10%'];

    readonly defaultTipSelection: string = '20%';
    tipSelection: string = this.defaultTipSelection;

    readonly defaultCustomTipSelection: number = 5;
    customTipPercent: number = this.defaultCustomTipSelection;
    
    teamMemberId: number;
    teamMemberFirstName: string;
    teamMemberLastName: string;

    get tipPercentage(): number {
        if (this.tipSelection?.includes('%') === true) {
            return +this.tipSelection.replace('%', '');
        } else if (this.tipSelection === 'Other' && this.customTipPercent) {
            return this.customTipPercent;
        } else {
            return 0;
        }
    }

    get tipDollars(): number {
        if (this.editOrderTipDollars != null) {
            return this.editOrderTipDollars;
        }

        if (this.tipSelection == 'None') {
            return 0;
        }
        if (this.tipSelection == 'Other') {
            if (!this.customTipPercent || !this.isCustomTipValid) {
                return 0;
            }
            return this.donationValue * this.customTipPercent / 100;
        }
        if (this.tipSelection) {
            return this.donationValue * +this.tipSelection.replace('%', '') / 100;
        }
        return 0;
    }

    // the dollars version of the tip, for use in editing an order,
    // where we'd prefer to set the tip as a dollar amount to make it easier
    // go get the order total to come out correctly.
    editOrderTipDollars: number;

    get isCustomTipValid(): boolean {
        if (this.tipSelection != 'Other' || this.orderDetailId) {
            return true;
        }
        if (this.customTipPercent == 0) {
            return true;
        }
        if (this.customTipPercent > 0 && this.customTipPercent % 1 == 0) {
            return true;
        }
        return false;
    }

    phoneErrorMessage: string = '';

    constructor(
        injector: Injector,
        private _sharedOrdersServiceProxy: SharedOrdersServiceProxy,
        private _orderDetailsServiceProxy: OrderDetailsServiceProxy,
        private _currencyPipe: CurrencyPipe,
        private _router: Router,
        private _repService: RepServiceProxy,
        private renderer: Renderer2,
        private _listItemsService: ListItemsServiceProxy,
        private _fundraisersServiceProxy: FundraisersServiceProxy,
        private Location:Location,
        @Inject(DOCUMENT) private document: Document
    ) {
        super(injector);
        this.addSweetAlertFix();
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'swal2-iosfix');
    }

    ngOnInit(): void {
        window.scroll({
            top: 0,
            left: 0
        });
        this.editOrderTipDollars = this.orderDetail?.tipDollars;
        if (this.isInitialLoadForEdit && this.orderDetailId) {
            // user is backoffice, editing an existing order.
            this.paidIsSet = true;
            this._orderDetailsServiceProxy.getOrderDetailForEdit(this.orderDetailId).subscribe(result => {
                this.orderDetail = result.orderDetail;
                if (!this.orderDetail.shippingAddress) {
                    this.orderDetail.shippingAddress = new AddressDto();
                }
                if (this.orderDetail.shippingAddress?.stateId == null) {
                    this.orderDetail.shippingAddress.stateId = undefined;
                }
                this.editOrderTipDollars = this.orderDetail.tipDollars;

                this.orderDetail.customDonation = this.orderDetail.donationAmount;

                this.availableSellers = result.availableSellers;
                this.fundraiserName = result.fundraiserName;
                this.fundraiserIdInput = this.orderDetail.fundraiserId;

                let isDoneGettingFundraiserPaymentMethods = false;
                let isDoneGettingAllProductsForFundraiser = false;

                this.setShippingAddress = this.orderDetail.shippingAddress?.streetLine1 != null;

                this._sharedOrdersServiceProxy.getFundraiserPaymentMethods(this.orderDetail.fundraiserId).subscribe(result => {
                    this.paymentMethods = result.paymentMethods;
                    this.checksPayableName = result.checksPayableName;
                    this.creditCardText = result.creditCardText;
                    this.convenienceFee = result.convenienceFee;
                    this.directShipAvailable = result.directShipAvailable;
                    this.forceDirectShip = result.forceDirectShip;
                    this.acceptDonations = result.acceptDonations;

                    this._listItemsService.getListItemsForLookupTable('paymentmethods').subscribe(result => {
                        this.paymentMethods = result.items;
                        let checkPaymentMethodId = this.paymentMethods.find(pm => pm.programValue == 'check')?.id;
                        let cashPaymentMethodId = this.paymentMethods.find(pm => pm.programValue == 'cash')?.id;

                        if (this.orderDetail.paymentMethodId == checkPaymentMethodId || this.orderDetail.paymentMethodId == cashPaymentMethodId) {
                            this.guestPayMode = this.PAY_INPERSON;
                        }
                        else {
                            this.guestPayCCOption = this.PAY_CC;
                        }
                    });

                    if (!this.isGuest && result.isCreditCardOnly && !(this.orderDetail.id > 0))
                        this.studentCreditCardOnly = true;

                    if (this.forceDirectShip) {
                        this.orderDetail.directShip = true;
                        this.guestPayMode = this.guestPayCCOption;
                    }

                    isDoneGettingFundraiserPaymentMethods = true;
                    if (!this.isInitialLoadForEdit || isDoneGettingAllProductsForFundraiser) {
                        // now that we have guestPayMode, updateTotals to get convenience fee:
                        this.updateTotals();
                    }

                });

                this._fundraisersServiceProxy.getAllProductsForFundraiser(this.orderDetail.fundraiserId).subscribe(result => {
                    this.productAndPackageViewModels = [];
                    this.productOptions = {};
                    result.productOptions.forEach(x => this.productOptions[x.id] = x);

                    result.productsAndPackages.forEach((productOrPackage) => {
                        // skip inactive products if user is a customer creating a new order.
                        // else, user is an admin, editing an order, and needs to see inactive products.
                        if (this.orderDetailId || productOrPackage.isActive) {
                            var ppvm = new ProductOrPackageViewModel();
                            ppvm.productDetails = productOrPackage;
                            if (ppvm.productDetails && ppvm.productDetails.id) {
                                ppvm.orderProducts = [];
                                ppvm.productOptions = result.productOptions.filter(x => ppvm.productDetails.productOptionIds?.indexOf(x.id) >= 0);

                                if (ppvm.productOptions && ppvm.productOptions.length > 0) {
                                    for (let opt of ppvm.productOptions) {
                                        let orderProduct = this.setupOrderProduct(ppvm.productDetails.id, ppvm.productDetails.isPackage, opt.id);
                                        ppvm.orderProducts.push(orderProduct);
                                    }
                                } else {
                                    let orderProduct = this.setupOrderProduct(ppvm.productDetails.id, ppvm.productDetails.isPackage);
                                    ppvm.orderProducts.push(orderProduct);
                                }

                                this.productAndPackageViewModels.push(ppvm);
                            }
                        }
                    });

                    isDoneGettingAllProductsForFundraiser = true;
                    if (isDoneGettingFundraiserPaymentMethods) {
                        this.updateTotals();
                    }
                });

                this.setupViewForExistingOrder();
            });
            this._sharedOrdersServiceProxy.getListItemsForLookupTable('states', undefined, 0, 100000).subscribe(result => {
                this.states = result.items;
            });
        }
        else if (!this.orderDetailId) {
            this.setupViewForNewOrder();
            this.setupView();
        }
        else {
            this.setupView();
            this.setupViewForExistingOrder();
        }
    }

    setupOrderProduct(productId: number, isPackage: boolean, productOptionId: number = undefined): OrderProductOrPackageDto {
        let orderProduct = new OrderProductOrPackageDto();
        orderProduct.productOrPackageId = productId;
        orderProduct.productOptionId = productOptionId;

        var quantity = 0;
        var isPackage: boolean;
        if (this.orderDetail.orderProductsAndPackages) {
            var op = this.orderDetail.orderProductsAndPackages.find(x => x.productOrPackageId == productId && x.productOptionId == productOptionId);
            if (op) {
                quantity = op.quantity;
            }
            isPackage = isPackage;
        }
        orderProduct.quantity = quantity;
        orderProduct.isPackage = isPackage

        return orderProduct;
    }

    setupView() {
        if (this.donationAmountInput) {
            if (!this.isAProductSelected) {
                this.isDonationOnly = true;
            }
            else {
                // clear out the default tip (that we'd use for a launchathon fundraiser):
                this.tipSelection = 'None';
            }
        }
        else {
            // clear out the default tip (that we'd use for a launchathon fundraiser):
            this.tipSelection = 'None';
        }

        if (this.teamMemberIdInput) {
            this.teamMemberId = this.teamMemberIdInput;
        }

        this._donation = this.donationAmountInput ? this.donationAmountInput : 0;

        this.orderDetail.customDonation = this.donationAmountInput;

        this._sharedOrdersServiceProxy.getListItemsForLookupTable('states', undefined, 0, 100000).subscribe(result => {
            this.states = result.items;
        });

        if (!this.paymentMethods || this.paymentMethods.length == 0) {
            // launchathon home page doesn't pass paymentMethods
            this._listItemsService.getListItemsForLookupTable('paymentmethods').subscribe(result => {
                this.paymentMethods = result.items;
                if (this.orderDetail.paymentMethodId == null) {
                    this.orderDetail.paymentMethodId = this.paymentMethods.find(pm => pm.programValue == 'creditcard')?.id;
                }
                this.updateTotals();
            });
        }
        else {
            this.updateTotals();
        }
    }

    setupViewForNewOrder() {
        // I don't know why we're overwriting the orderDetail we get from the fundraiser home page component,
        // but we need to hold-on to the paymentMethodId:
        let paymentMethodId = this.orderDetail.paymentMethodId;

        this.orderDetail = new CreateOrEditOrderDetailDto();
        if (this.defaultOrderDetailToDirectShip) {
            this.orderDetail.directShip = true;
        }
        this.orderDetail.customerAddress = new AddressDto();
        this.orderDetail.shippingAddress = new AddressDto();
        this.orderDetail.creditCardInfo = new CreditCardDto();
        this.orderDetail.paymentMethodId = paymentMethodId;

        this.orderDetail.fundraiserStudentContactSlug = this.fundraiserStudentContactSlug;
    }

    setupViewForExistingOrder() {
        if (!this.orderDetailId) {
            this.message.error('Failed to load OrderDetail record for Id: ' + this.orderDetailId);
            return;
        }
        this.teamMemberId = this.orderDetail.studentId ?? -1;
        this.teamMemberFirstName = this.orderDetail.sellerFirstName?.trim();
        this.teamMemberLastName = this.orderDetail.sellerLastName?.trim();

        let addressNames = this.orderDetail.customerAddress?.name?.replace(/\s+/g, ' ')?.trim()?.split(' ');
        if (addressNames.length == 1) {
            this.firstName = addressNames[0];
        }
        else if (addressNames.length > 1) {
            this.firstName = addressNames.slice(0, addressNames.length - 1).join(' ');
            this.lastName = addressNames[addressNames.length - 1];
        }

        // instead of trying to match tipPercentage to a tip selection option,
        // let's just select the 'custom' option, and set the custom value to whatever the tipPercentage is.
        this.tipSelection = 'Other';
        this.customTipPercent = this.orderDetail.tipPercentage;
    }

    ngDoCheck() {
        // sweet alert clears out the swal2-iosfix when it closes,
        // but we need to put it back in case a sweet alert appears again(as for e.g.a confirmation modal).
        // everytime the dom changes, we'll check to make sure the class is on the body element.
        if (!document.body.classList.contains('swal2-iosfix')) {
            this.addSweetAlertFix();
        }
    }
    addSweetAlertFix() {
        // sweet alert component needs body to have swal2-iosfix when it loads.
        // it runs code to put the class there, but it doesn't put it there in time.
        this.renderer.addClass(document.body, 'swal2-iosfix');
    }

    get isAProductSelected(): boolean {
        return this.productAndPackageViewModels?.find(p => p.orderProducts?.find(op => op.quantity > 0) != null) != null;
    }

    readonly PAY_INPERSON = 0;
    readonly PAY_TEXTCC = 1;
    readonly PAY_CC = 2;

    paidIsSet: boolean = false;

    setShippingAddress = false;

    get showPaymentOptions(): boolean {
        return !this.isGuest;
    }

    get isPaymentMethodCreditCard(): boolean {
        if (!this.orderDetail?.paymentMethodId || !this.paymentMethods) {
            return false;
        }
        return this.orderDetail.paymentMethodId == this.paymentMethods.find(pm => pm.programValue == 'creditcard')?.id
    }

    get isPaymentMethodCheck(): boolean {
        if (!this.orderDetail?.paymentMethodId || !this.paymentMethods) {
            return false;
        }
        return this.orderDetail.paymentMethodId == this.paymentMethods.find(pm => pm.programValue == 'check')?.id
    }

    get invalidFieldMessages(): string[] {
        let invalidFieldMessages = [];

        // if the user isn't paying for anything, make that the sole error message:
        if (!(this.orderTotalsUpdateResult?.orderTotal + this.tipDollars)) {
            invalidFieldMessages.push('Your order is empty. Please select a donation or at least one item.');
            return invalidFieldMessages;
        }
        // if the user is a student and CC is the selected payment method, make that the sole error message:
        if (!this.isGuest && this.isPaymentMethodCreditCard) {
            invalidFieldMessages.push(this.l('YouMustBeLoggedOut'));
            return invalidFieldMessages;
        }
        // if payment type is check and no check number is entered, make that the sole error message:
        this.validateCheckNumber();
        if (this.checkNumberValidationMessage) {
            invalidFieldMessages.push(this.checkNumberValidationMessage);
            return invalidFieldMessages;
        }

        if (!this.orderForm?.form.valid) {
            for (const field in this.orderForm.form.controls) {
                const c = this.orderForm.form.get(field);
                if (c.invalid) {
                    invalidFieldMessages.push(field + ' is not valid');
                }
            }
        }
        if (!this.isCustomTipValid) {
            invalidFieldMessages.push('Tip is not valid');
        }
        if (!this.isDonationValid) {
            invalidFieldMessages.push('Donation is not valid');
        }
        if (this.showPaymentOptions && !this.paidIsSet) {
            invalidFieldMessages.push('Select Paid or Not Paid');
        }
        else if (!this.orderDetail.paymentMethodId) {
            invalidFieldMessages.push('Select a payment method');
        }

        this.validatePhoneNumber();
        if (this.phoneErrorMessage) {
            invalidFieldMessages.push('Phone is not valid');
        }

        // Don't validate when editing an order
        if (this.isGuest && !this.orderDetailId) {
            this.validateExpiresDate();
            if (this.expiresErrorMessage) {
                invalidFieldMessages.push('Expiration Date is not valid');
            }
        }
        return invalidFieldMessages;
    }

    isProcessingOrder: boolean = false;
    get donationValue(): number {
        if (!this.donation) {
            return 0;
        }
        return this.donation;
    }

    orderTransactionId: string;
    save(): void {
        // make any invalid fields' validation messages show:
        this.orderForm.form.markAllAsTouched();

        if (this.invalidFieldMessages?.length) {
            if (this.invalidFieldMessages.length < 3) {
                this.message.error(this.invalidFieldMessages.join(', '));
            }
            else {
                this.message.error("Please enter values for the required fields.");
            }
        }
        else {
            var confirmMessage = this.orderDetail.id ? this.l('UpdateOrder') + '?' : this.l('PlaceOrder') + '?';

            this.orderDetail.totalPrice = this.orderTotalsUpdateResult?.orderTotal ?? 0;
            let totalPlusTip = this.orderDetail.totalPrice + this.tipDollars;
            var totalPriceText = this.l('OrderTotal') + this._currencyPipe.transform(totalPlusTip, '$');
            this.message.confirm(confirmMessage, totalPriceText, (isConfirmed) => {
                if (isConfirmed) {
                    this.isProcessingOrder = true;

                    this.updatePaymentMethod();

                    if (this.orderDetail.creditCardInfo) {
                        this.orderDetail.creditCardInfo.expireMonth = this.expires?.split('/')[0];
                        this.orderDetail.creditCardInfo.expireYear = this.expires?.split('/')[1];
                    }
                    this.orderDetail.customerAddress.name = this.firstName?.trim() + (this.lastName?.trim() ? ' ' + this.lastName?.trim() : '');
                    this.orderDetail.orderTransactionId = this.newGuid();
                    this.orderDetail.fundraiserId = this.fundraiserIdInput;
                    this.orderDetail.fundraiserUserId = this.fundraiserUserIdInput;
                    this.orderDetail.path = this.Location.path();

                    this.orderDetail.customDonation = this.donationValue;

                    this.orderDetail.tipDollars = this.tipDollars;
                    if (this.editOrderTipDollars != null) {
                        this.orderDetail.tipPercentage = (this.tipDollars / this.donationValue) * 100;
                    }
                    else {
                        this.orderDetail.tipPercentage = this.tipPercentage;
                    }

                    this.orderDetail.customerAddress.phoneNumber = this.orderDetail.customerAddress.phoneNumber?.replace('x_', '').replace(/_/g, '').trim();

                    if (this.teamMemberId && this.teamMemberId > 0) {
                        this.orderDetail.studentId = this.teamMemberId;
                    }
                    else {
                        this.orderDetail.studentId = null;
                    }
                    this.orderDetail.sellerFirstName = this.teamMemberFirstName?.trim();
                    this.orderDetail.sellerLastName = this.teamMemberLastName?.trim();

                    if (this.orderDetail.customerAddress.emailAddress == '') {
                        this.orderDetail.customerAddress.emailAddress = undefined;
                    }
                    if (!this.setShippingAddress && this.orderDetail.shippingAddress) {
                        // saving with nothing set for shipping address;
                        // in case the shippingAddress fields hold values, clear them out.
                        this.orderDetail.shippingAddress = null;
                    }
                    else if (this.setShippingAddress && this.orderDetail.shippingAddress) {
                        this.orderDetail.shippingAddress.emailAddress = this.orderDetail.customerAddress.emailAddress;
                    }

                    this.orderDetail.automatedMessageType = this.automatedMessageType;

                    this.orderDetail.isISponsorEnabled = this.isISponsorEnabled;

                    this._sharedOrdersServiceProxy.createOrEdit(this.orderDetail)
                        .pipe(finalize(() => {
                            this.isProcessingOrder = false;
                        }))
                        .subscribe((result) => {
                            if (this.orderDetail.id) {
                                // user was editing an order
                                this._router.navigate(['/app/main/orders/orderDetails', this.orderDetail.fundraiserId]);
                            }
                            else {
                                // user was placing an order
                                var orderCompleteInfo: CompleteOrderInfo = {
                                    customerEmailAddress: this.orderDetail.customerAddress.emailAddress,
                                    orderAmount: this.orderDetail.customDonation,
                                    orderTransactionId: result.item1,
                                    isponsorInformation: result.item2,
                                }
                                this.resetUi();
                                this.orderComplete.emit(orderCompleteInfo);
                                orderCompleteInfo.isponsorInformation = null;
                            }
                        }, (error: any) => {
                        });
                }
            });
        }
    }

    updatePaymentMethod(): void {
        // if student is placing the order keep the form values
        // if guest is checking out update the payment settings based on payment mode
        if (this.isGuest && !this.orderDetail.id) {
            // always set paid to false
            // the integrated credit card processing will mark it paid when it completes
            // for other payment methods the student will edit the order and mark paid later
            this.orderDetail.paid = false;

            // set the payment method
            if (this.guestPayMode == this.PAY_INPERSON) {
                var cashPm = this.paymentMethods.find(x => x.abbreviation === 'ca');
                if (cashPm) this.orderDetail.paymentMethodId = cashPm.id;
            } else if (this.guestPayMode == this.PAY_TEXTCC || this.guestPayMode == this.PAY_CC) {
                var ccPm = this.paymentMethods.find(x => x.abbreviation === 'cc');
                if (ccPm) this.orderDetail.paymentMethodId = ccPm.id;
            }
        }
    }

    resetUi() {
        this.orderDetail.customDonation = 0;
        this._donation = 0;
        this.orderDetail.tipPercentage = 0;
        this.orderDetail.tipDollars = 0;
        this.tipSelection = this.defaultTipSelection;
        this.customTipPercent = undefined;
    }

    cancel() {
        this.message.confirm('', this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this.cancelOrder.emit(null);
            }
        });
    }

    showTipInfo() {
        this.message.info(this.tipInfo, this.tipInfoTitle);
    }

    validatePhoneNumber() {
        let isRequired = !this.orderDetailId && this.isGuest && this.isPaymentMethodCreditCard;
        this.phoneErrorMessage = !isRequired && !this.orderDetail.customerAddress?.phoneNumber ?
            "" : this.getPhoneErrorMessage(this.orderDetail.customerAddress?.phoneNumber);
    }

    expiresErrorMessage: string = '';
    validateExpiresDate() {
        this.expiresErrorMessage = '';
        if (!this.expires || this.expires.replace(/[\W_/]+/g, '') == '') {
            this.expiresErrorMessage = 'This field is required';
        }
        // input mask characters come through; replace them to count up just the digits.
        else if (this.expires.replace(/[\W_A-Za-z]+/g, '').length < 4) {
            this.expiresErrorMessage = 'Must be MM/YY';
        }
    }

    checkNumberValidationMessage: string = '';
    validateCheckNumber() {
        this.checkNumberValidationMessage = '';
        if (this.isPaymentMethodCheck && !this.orderDetail.checkNumber) {
            this.checkNumberValidationMessage = this.l('CheckNumberErrorMessage');
        }
    }

    customerCCOrderEmailAddress: string;
    sendSharedOrder(): void {
        if (!this.customerCCOrderEmailAddress) {
            this.message.error("Email Address is Required");
            return;
        }
        if (!this.isValidEmailAddress(this.customerCCOrderEmailAddress)) {
            this.message.error("Email Address is Invalid");
            return;
        }

        // make call to service
        this._sharedOrdersServiceProxy.sendSharedOrder(this.fundraiserUserIdInput, this.customerCCOrderEmailAddress, this.shareLink)
            .subscribe(() => {
                this.message.success("", "Order has been sent");
                this._router.navigate(["/app/main/fundraiser", this.fundraiserIdInput]);
            });
    }

    incrementQuantity(productOption: ProductOption): void {
        let product = this.productAndPackageViewModels.find(pvm => pvm.productDetails.id == productOption.productId);
        let opm = product.orderProducts.find(op => op.productOptionId == productOption.productOptionId);
        opm.quantity++;
        this.updateTotals();
    }

    decrementQuantity(productOption: ProductOption, allowZero: boolean = true): void {
        let product = this.productAndPackageViewModels.find(pvm => pvm.productDetails.id == productOption.productId);
        let opm = product.orderProducts.find(op => op.productOptionId == productOption.productOptionId);
        if (!allowZero && productOption.quantity === 1) {
            this.removeProduct(productOption);
        } else {
            if (opm.quantity > 0)
                opm.quantity--;
            this.updateTotals();
        }
    }

    removeProduct(productOption: ProductOption): void {
        this.message.confirm('',
            this.l('RemoveProductFromCart'),
            (isConfirmed) => {
                if (isConfirmed) {
                    let product = this.productAndPackageViewModels.find(pvm => pvm.productDetails.id == productOption.productId);
                    let opm = product.orderProducts.find(op => op.productOptionId == productOption.productOptionId);
                    opm.quantity = 0;
                    this.updateTotals();
                }
            });
    }

    orderContainsDGCSelection: boolean;
    async updateTotals() {
        this.setSelectedProductOrders();
        this.orderDetail.orderProductsAndPackages = [];
        for (var k in this.productAndPackageViewModels) {
            this.orderDetail.orderProductsAndPackages.push(...this.productAndPackageViewModels[k].orderProducts);
        }

        let orderTotalsInput = new OrderTotalsInput();
        orderTotalsInput.fundraiserId = this.fundraiserIdInput;

        // creditCardPayment is used to figure whether to add a convenience fee.
        let paymentMethodOrderWasPlacedWith = this.orderDetail.paymentMethodId;
        if (paymentMethodOrderWasPlacedWith) {
            // if user is editing an order, then we'll check what was saved as the payment method
            orderTotalsInput.creditCardPayment = this.orderDetail.paymentMethodId == 60;
        }
        else {
            // else it depends on whether a student is placing the order for a customer (i.e. cash or check),
            // or the customer is placing it directly (i.e. credit card)
            orderTotalsInput.creditCardPayment = this.guestPayMode == this.PAY_CC;
        }

        orderTotalsInput.directShip = this.orderDetail.directShip;
        orderTotalsInput.fundraiserId = this.fundraiserIdInput;
        orderTotalsInput.orderProductsAndPackages = this.orderDetail.orderProductsAndPackages;
        orderTotalsInput.customDonation = this.orderDetail.customDonation;
        orderTotalsInput.selectedDonation = this.orderDetail.selectedDonation;
        orderTotalsInput.donateByRoundingOrderTo = this.donateByRoundingOrderTo;

        this.orderContainsDGCSelection = false;
        await this._sharedOrdersServiceProxy.updateOrderTotals(false, orderTotalsInput)
            .subscribe(result => {
                this.orderTotalsUpdateResult = result;
                if (this.orderTotalsUpdateResult.totalItems == 0) {
                    this.orderDetail.directShip = false;
                    if (!this.isDonationOnly && !this.orderDetailId) {
                        this.tipSelection = this.defaultTipSelection;
                    }
                    this.isDonationOnly = true;
                }
                else {
                    if (this.productAndPackageViewModels.find(p => p.productDetails.isDigitalGoldCard && p.orderProducts.find(op => op.quantity > 0))) {
                        this.orderContainsDGCSelection = true;
                    }
                }
                this._donation = this.orderTotalsUpdateResult.donationTotal;
            });
    }

    continueShopping(): void {
        if (this.isLaunchathon && !this.orderDetailId) {
            this.cancel();
        }
        else {
            let orderDetailPlusProductViewModels: OrderDetailPlusProductAndPackageViewModels = new OrderDetailPlusProductAndPackageViewModels();
            orderDetailPlusProductViewModels.productAndPackageViewModels = this.productAndPackageViewModels;
            orderDetailPlusProductViewModels.orderDetail = this.orderDetail;
            this.goBack.emit(orderDetailPlusProductViewModels);
        }
    }

    updateShipOption(directShip: boolean) {
        if (directShip) {
            // seems to clear-out billing address city, state, zip...
            this.orderDetail.directShip = true;
            this.guestPayMode = this.guestPayCCOption;
            this.updateTotals();
        } else {
            this.orderDetail.directShip = false;
            this.updateTotals();
        }
    }

    get isDonationValid(): boolean {
        // this.orderDetailId means that an admin-type user is editing the order.
        if (this.orderDetailId || this.donateByRoundingOrderTo || this.donation == 0 || this.donation >= 10) {
            return true;
        }
        return false;
    }
}

export class ProductOption {
    productName: string;
    productOptionName: string;
    price: number;
    quantity: number;
    productId: number;
    productOptionId: number;
}

export interface CompleteOrderInfo {
    customerEmailAddress: string;
    orderAmount: number;
    orderTransactionId: string;
    isponsorInformation?: CreateISponsorAccountDto;
}

export class OrderDetailPlusProductAndPackageViewModels {
    productAndPackageViewModels: ProductOrPackageViewModel[];
    orderDetail: CreateOrEditOrderDetailDto;
}