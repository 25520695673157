import { Component, EventEmitter, Injector, Input, Output, ViewChild } from "@angular/core";
import { CreateOrEditOrderDetailDto, FundraiserUserSelector, ListItemLookupTableDto, OrderTotalsDto } from "../../../../../shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { NgModel } from "@angular/forms";
import { AppConsts } from "@shared/AppConsts";

@Component({
    selector: 'checkout-order-details',
    templateUrl: './checkout-order-details.component.html',
    styleUrls: ['./checkout-order-details.component.less']
})
export class CheckoutOrderDetailsComponent extends AppComponentBase {
    
    @Input() orderDetail: CreateOrEditOrderDetailDto;
    @Input() isGuest: boolean;
    @Input() directShipAvailable: boolean;
    @Input() orderTotalsUpdateResult: OrderTotalsDto;
    @Input() forceDirectShip: boolean;
    @Input() paymentMethods: ListItemLookupTableDto[];
    @Input() states: ListItemLookupTableDto[];
    @Input() orderContainsDGCSelection: boolean;
    @Input() teamMemberNameInput: string;
    @Input() availableSellers: FundraiserUserSelector[];
    @Input() acceptDonations: boolean;
    @Input() isDonationOnly: boolean;
    @Input() isLaunchathon = false;
    @Input() donationAmountInput: number;
    @Input() donateByRoundingOrderTo: number;
    @Input() teamMemberId: number;
    @Input() tipInfoTitle: string;
    @Input() tipInfo: string;
    @Input() orderTotal: number;
    @Input() automatedMessageType: string;

    // Order Details
    @ViewChild('teamMemberSelect', {static: false}) teamMemberSelect: NgModel;
    @ViewChild('teamMemberFirstNameInput', {static: false}) teamMemberFirstNameInput: NgModel;
    @ViewChild('teamMemberLastNameInput', {static: false}) teamMemberLastNameInput: NgModel;
    
    // Billing Information
    @ViewChild('addressBillingFirstNameInput', {static: false}) addressBillingFirstNameInput: NgModel;
    @ViewChild('addressBillingLastNameInput', {static: false}) addressBillingLastNameInput: NgModel;
    @ViewChild('streetInput', {static: false}) streetInput: NgModel;
    @ViewChild('streetInput2', {static: false}) streetInput2: NgModel;
    @ViewChild('cityInput', {static: false}) cityInput: NgModel;
    @ViewChild('stateSelect', {static: false}) stateSelect: NgModel;
    @ViewChild('zipInput', {static: false}) zipInput: NgModel;
    @ViewChild('phoneInput', {static: false}) phoneInput: NgModel;
    @ViewChild('emailInput', {static: false}) emailInput: NgModel;
    
    // Shipping Information
    @ViewChild('shippingName', {static: false}) shippingName: NgModel;
    @ViewChild('shippingStreet', {static: false}) shippingStreet: NgModel;
    @ViewChild('shippingStreet2', {static: false}) shippingStreet2: NgModel;
    @ViewChild('shippingCity', {static: false}) shippingCity: NgModel;
    @ViewChild('shippingState', {static: false}) shippingState: NgModel;
    @ViewChild('shippingZip', {static: false}) shippingZip: NgModel;

    @Output() updateTotalsOutput: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateShipOptionOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() goToPaymentMethodsOutput: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateDonateByRoundingOrderTo: EventEmitter<number> = new EventEmitter<number>();


    // Order Details
    _donation: number;
    get donation(): number {
        return this._donation;
    }
    set donation(value: number) {
        this._donation = value;
        this.orderDetail.customDonation = this.donationValue;

        // if user directly edits the donation total,
        // we no - longer want donation value to track the item total price value
        // as it would if they selected a round-up donation value.
        this.donateByRoundingOrderTo = 0;
        this.updateDonateByRoundingOrderTo.emit(this.donateByRoundingOrderTo);
    }

    tipOptions: string[] = ['Other', '30%', '25%', '20%', '15%', '10%'];

    readonly defaultTipSelection: string = '20%';
    tipSelection: string = this.defaultTipSelection;

    readonly defaultCustomTipSelection: number = 5;
    customTipPercent: number = this.defaultCustomTipSelection;
    
    teamMemberFirstName: string;
    teamMemberLastName: string;



    get donationValue(): number {
        return this.donation || 0;
    }

    get tipPercentage(): number {
        if (this.orderAllowsTip && this.tipSelection) {
            if (this.tipSelection?.includes('%') === true) {
                return +this.tipSelection.replace('%', '');
            } 
            
            if (this.tipSelection === 'Other' && this.customTipPercent) {
                return this.customTipPercent;
            }
        }

        return 0;
    }

    get tipDollars(): number {
        if (this.editOrderTipDollars != null) {
            return this.editOrderTipDollars;
        } 

        // If the tip is visible on the page, and the user has selected a tip value
        if (this.orderAllowsTip && this.tipSelection) {
            if (this.tipSelection !== 'Other' && this.tipSelection !== 'None') {
                return this.donationValue * +this.tipSelection.replace('%', '') / 100;
            } 
            
            if (this.tipSelection === 'Other' && this.customTipPercent && this.isCustomTipValid) {
                return this.donationValue * this.customTipPercent / 100;
            }
        }

        return 0;
    }

    get orderAllowsTip(): boolean {
        return (!this.isLaunchathon && this.isDonationOnly) || (this.isLaunchathon && this.isGuest);
    }

    // the dollars version of the tip, for use in editing an order,
    // where we'd prefer to set the tip as a dollar amount to make it easier
    // go get the order total to come out correctly.
    editOrderTipDollars: number;

    get isCustomTipValid(): boolean {
        if (this.tipSelection !== 'Other' || this.orderDetail?.id) {
            return true;
        }
        if (this.customTipPercent === 0 || (this.customTipPercent > 0 && this.customTipPercent % 1 === 0)) {
            return true;
        }
        return false;
    }

    get isDonationValid(): boolean {
        // this.orderDetailId means that an admin-type user is editing the order.
        if (this.orderDetail?.id || this.donateByRoundingOrderTo || (!this.isLaunchathon && this.donation == 0) || this.donation >= 10) {
            return true;
        }
        return false;
    }

    // Shipping / Billing Details
    firstName: string;
    lastName: string = '';

    phoneErrorMessage: string;

    setShippingAddress = false;

    get isPaymentMethodCreditCard(): boolean {
        if (!this.orderDetail?.paymentMethodId || !this.paymentMethods) {
            return false;
        }
        return this.orderDetail.paymentMethodId == this.paymentMethods.find(pm => pm.programValue == AppConsts.creditcard)?.id
    }

    get isPaymentMethodCheck(): boolean {
        if (!this.orderDetail?.paymentMethodId || !this.paymentMethods) {
            return false;
        }
        return this.orderDetail.paymentMethodId == this.paymentMethods.find(pm => pm.programValue == AppConsts.check)?.id
    }

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this._donation = this.donationAmountInput;
    }

    isANewOrder(): boolean {
        return !this.orderDetail.id;
    }

    showTipInfo() {
        this.message.info(this.tipInfo, this.tipInfoTitle);
    }

    updateTotals() {
        // When editing an order, if the tip is cleared out, set it to 0
        if (!this.isANewOrder() && (this.editOrderTipDollars == null || this.editOrderTipDollars < 0)) {
            this.editOrderTipDollars = 0;
        }

        this.updateTotalsOutput.emit();
    }

    validatePhoneNumber() {
        let isRequired = !this.orderDetail?.id && this.isGuest && this.isPaymentMethodCreditCard;
        this.phoneErrorMessage = !isRequired && !this.orderDetail.customerAddress?.phoneNumber ?
            "" : this.getPhoneErrorMessage(this.orderDetail.customerAddress?.phoneNumber);
    }

    updateShipOption(directShip: boolean) {
        this.updateShipOptionOutput.emit(directShip);
    }

    goToPaymentMethods() {
        // If there is no order total, make this the only error
        if (!((this.orderTotal ?? 0) + this.tipDollars)) {
            this.message.error(`${this.l('EmptyOrder1')}${!this.isLaunchathon ? ' ' + this.l('EmptyOrder2') : '.'}`);
            return;
        }

        var invalidFields = [];

        const validationInputs = [
            this.teamMemberSelect,
            this.teamMemberFirstNameInput,
            this.teamMemberLastNameInput,
            this.addressBillingFirstNameInput,
            this.addressBillingLastNameInput,
            this.streetInput,
            this.cityInput,
            this.stateSelect,
            this.zipInput,
            this.emailInput,
            this.shippingName,
            this.shippingStreet,
            this.shippingCity,
            this.shippingState,
            this.shippingZip
        ]

        validationInputs.forEach(field => {
            if (field) { // if field is hidden by ngIf then it will be undefined
                field.control.markAsTouched();
                if (field.control.invalid) {
                    invalidFields.push(field.name);
                }
            }
        });
        
        if ((this.acceptDonations || this.isLaunchathon) && !this.isDonationValid) {
            invalidFields.push(this.l('Donation'));
        }

        if(this.isANewOrder() && ((!this.isLaunchathon && this.isDonationOnly) || (this.isLaunchathon && this.isGuest)) 
            && !this.isCustomTipValid) {
                invalidFields.push(this.l('CustomTip')); 
        }

        if(this.isGuest && this.isPaymentMethodCreditCard && this.isANewOrder()) {
            this.validatePhoneNumber();
            // undefined will still make this check true because undefined !== ''
            if (this.phoneErrorMessage !== '') {
                invalidFields.push(this.l('Phone Number'));
            }
        }

        if (invalidFields.length > 0) {
            var message = `${this.l('PleaseReviewFields')}</br> ${invalidFields.join('</br>')}`;
            this.message.error(message, '', { isHtml: true });
            return;
        }

        // set Customer Address Name
        this.orderDetail.customerAddress.name = `${this.firstName.trim()} ${this.lastName ?? ''}`.trim();
        this.goToPaymentMethodsOutput.emit();
    }
}