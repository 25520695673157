import { Component, EventEmitter, Injector, Input, Output } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AddressDto, CreateOrEditOrderDetailDto, ListItemLookupTableDto } from "@shared/service-proxies/service-proxies";
import { ApplePayEventInfo } from "../checkout-payment-methods-applepay/checkout-payment-methods-applepay.component";

@Component({
    selector: 'checkout-review-order',
    templateUrl: './checkout-review-order.component.html',
    styleUrls: ['./checkout-review-order.component.less']
})
export class CheckoutReviewOrderComponent extends AppComponentBase {

    @Input() orderDetail: CreateOrEditOrderDetailDto;
    @Input() state: string;
    @Input() isLaunchathon: boolean;
    @Input() isDonationOnly: boolean;
    @Input() paymentMethodName: string;
    @Input() isGuest: boolean;
    @Input() paymentMethods: ListItemLookupTableDto[];
    @Input() isUpdatingTotals: boolean = false;
    @Input() totalAmount: number;
    @Input() organizationName: string;
    @Input() isApplePayEnabledForFundraiser: boolean;
    @Output() completeCheckOutOrderOutput: EventEmitter<any> = new EventEmitter<any>();
    @Output() applePayEvent: EventEmitter<ApplePayEventInfo> = new EventEmitter<ApplePayEventInfo>();

    check = AppConsts.check;
    creditcard = AppConsts.creditcard;
    applePay = AppConsts.applepay;

    get isPaidOrNotPaid(): string {
        return this.orderDetail.paid ? 'Paid' : 'Not Paid';
    }

    get IsDeliveredOrNotDelivered(): string {
        return this.orderDetail.delivered ? 'Delivered' : 'Not Delivered';
    }

    isPaymentMethod(paymentMethod: string): boolean {
        if(!this.paymentMethods || !this.orderDetail?.paymentMethodId) {
            return false;
        }
        return this.orderDetail.paymentMethodId == this.paymentMethods.find(pm => pm.programValue == paymentMethod)?.id
    };


    shippingAddress: AddressDto = new AddressDto();

    constructor(injector: Injector) {
        super(injector);
    }

    completeCheckoutOrder(): void {
        this.completeCheckOutOrderOutput.emit();
    }

    maskCCNumber(ccNumber: string): string {
        return ccNumber ? 'xxxx xxxx xxxx ' + ccNumber.slice(ccNumber.length - 4) : '';
    }

    isANewOrder(): boolean {
        return !this.orderDetail.id;
    }

    submitApplePayEvent(event: ApplePayEventInfo) {
        this.applePayEvent.emit(event);
    }
}